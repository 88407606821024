.metrics-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 400px;
    min-width: 250px !important;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 15px;
    color: #6fbfe1;
}

.metrics-header {
    width: 100%;
    height: 48px;
    display: flex;
    padding: 5px;
    color: #6fbfe1;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
}

.add-metric {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    background: #6fbfe1d9;
}

.add-metric:hover {
    background: #6fbfe1;
}

#search-metric::placeholder {
    color: #6fbfe180;
}

.add-metric-title {
    display: flex;
    align-items: center;
    padding: 5px;
}

.add_metric_body {
    height: calc(100% - 40px);
    overflow-y: auto;
}

.add_metric_body .metric_select {
    margin: 10px 0;
}

.add_metric_body .metric_select .input-box {
    width: 100%;
    border: 1px solid #6fbfe140;
    background: #6fbfe11a;
    border-radius: 7px;
    overflow: hidden;
    height: 40px;
}

.add_metric_body .metric_select .input-box input {
    border: 0;
    outline: none;
    color: #6fbfe180;
    height: 100%;
    font-size: 12px;
    width: 72%;
    padding: 12px;
    font-weight: 400;
    background-color: transparent;
}
.add_metric_body .metric_select .percentage_icon {
    width: 50px;
    background: #6fbfe133;
    color: #6fbfe180;
    height: 100%;
    border-radius: 0;
}
.add_metric_body .metric_select label {
    max-width: 110px;
    width: 100%;
    color: #6fbfe1;
    font-size: 12px;
    font-weight: 400;
}
.add-metric-title .description_input_box{
    width: 100%;
}
.add-metric-title .description_input_box .input_field_box input {
    font-weight: 400;
    font-size: 14px;
    font-style: italic;
}
.add-metric-title .group {
    height: 26px;
}

.add_metric_body .metric_select .weight_slider .input-box input {
    width: 100%;
}
.add_metric_body .metric_select .weight_slider {
    width: 100%;
}
.metric_horizontal_line {
    margin: 15px 0 !important ;
}
.create-metric-container {
    color: rgba(111, 191, 225, 0.75);
    display: block;
    gap: 10px;
    font-size: 12px;
    overflow-x: hidden;
    padding-right: 5px;
    flex-grow: 1;
}

#metric-description::placeholder {
    color: #6fbfe180;
}
.option_img {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
}

#metric-description:hover {
    border: 1px solid #6fbfe180;
}

.delete-metric {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
}

.delete-metric:hover {
    background: #6fbfe11a;
}

.delete-metric:hover svg {
    fill-opacity: 1;
}

#metric-format:hover {
    border: 1px solid #6fbfe180;
}

#metric-format:focus {
    border: 1px solid rgba(111, 191, 225, 0.5) !important;
}

.metric-child-component {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.metric-child-component p {
    color: #6fbfe1;
    font-size: 12px;
    font-weight: 500;
}

.metric-select-border {
    border: 1px solid #6fbfe140;
}

.metric-select-border:hover {
    border: 1px solid #6fbfe180;
}

.metric-select-text {
    color: #6fbfe180;
}

.metric-select-text:hover {
    color: #6fbfe1bf;
}

.edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    width: 24px;
    height: 24px;
}

.edit-button:hover {
    background: #6fbfe10d;
}

.edit-button:hover svg {
    fill-opacity: 1;
}

.metric-filters {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
}

.metric-filter {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.metric-filter:hover {
    background: #6fbfe11a;
}

.metric-filter-selected {
    background: #6fbfe11a;
}

.sort-menu-list {
    min-width: 120px;
    border-radius: 7px;
    box-shadow: 0px 4px 20px rgba(15, 16, 68, 0.18);
    background-color: #fff;
    font-size: 12px;
}
#metric-sort-menu .MuiPopover-paper {
    margin-top: 5px;
}
.sort-btn:hover svg {
    fill-opacity: 1;
}

.metric-responses {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
    gap: 10px;
    padding-right: 5px;
}

.metric-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 80px;
}

.metric-scrollbar::-webkit-scrollbar-track {
    border-radius: 50px;
    background-color: rgba(111, 191, 225, 0.1);
}

.metric-scrollbar::-webkit-scrollbar-thumb {
    width: 4px;
    background: rgba(111, 191, 225, 0.15);
    border-radius: 50px;
    /* border: 3px solid #f6f7ed; */
}

.metric-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(111, 191, 225, 0.2);
}

.metric-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    overflow: initial;
    position: relative;
}

.metric-card:disabled {
    opacity: 0.5;
}

.metric-card:hover {
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.fav-btn {
    width: 24px;
    height: 24px;
    display: flex;
    border-radius: 50px;
    background: #ffffff1a;
    justify-content: center;
    align-items: center;
}

.fav-btn-selected svg {
    fill-opacity: 1;
}

.fav-btn:hover svg {
    fill-opacity: 1;
}

.fav-btn:disabled {
    cursor: not-allowed;
}

.metric-category {
    width: 12px;
    height: 12px;
    border-radius: 4px;
}

.metric-name-card {
    display: flex;
    align-items: center;
    height: 32px;
    border-radius: 50px;
    background-color: #ffffff1a;
    border: 1px solid #ffffff1a;
    box-shadow: 0px 3px 6px 0px #0f104408;
    padding: 5px 8px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;
    gap: 8px;
}

.metric-icon-container {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ffffff;
    gap: 8px;
}

.metric-percentage {
    background: #e3f2fd;
}

.metric-ratio {
    background: #e8f5e9;
}

.metric-score {
    background: #fff8e1;
}

.metric-binary {
    background: #f3e5f5;
}

.metric-trend {
    background: #fff3e0;
}

.metric-trend-stable {
    background: #eceff1;
}

.metric-index {
    background: #eceff1;
}

.metric-rank {
    background: #fff3e0;
}

.metric-id {
    background: #e0f7fa;
}

.metric-count {
    background: #e0f7fa;
}

.metric-growth {
    background: #e0f2f1;
}

.metric-velocity {
    background: #fbe9e7;
}

.metric-forecast {
    background: #ede7f6;
}

.metric-risk-low {
    background: #f1f8e9;
}

.metric-risk-medium {
    background: #fff8e1;
}

.metric-risk-high {
    background: #ffebee;
}

.metric-risk-low svg {
    fill: #9ccc65;
}

.metric-risk-medium svg {
    fill: #ffca28;
}

.metric-risk-high svg {
    fill: #ef5350;
}

.metric-card-expanded {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    padding: 0 10px 10px 10px;
}

.vertical-line {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 1px;
    height: 14px;
    background: #ffffff40;
}

.thumbs-up:hover svg,
.thumbs-down:hover svg {
    stroke-opacity: 1;
}

.thumbs-up-selected svg,
.thumbs-down-selected svg {
    fill: #ffff;
}

.lock-btn {
    width: 24px;
    height: 24px;
    display: flex;
    border-radius: 50px;
    background: #ffffff1a;
    justify-content: center;
    align-items: center;
}

.lock-btn-selected {
    background: #000000 !important;
}

.edit-icon:hover svg,
.cross-icon:hover svg {
    fill-opacity: 1;
}

.available-icon {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background: #6fbfe1;
}

.protocol {
    display: flex;
    align-items: center;
    width: 100%;
    height: 24px;
    border-radius: 3px;
    padding: 4px;
    gap: 8px;
    background: #ffffff0d;
    position: relative;
    color: #ffffffbf;
}

.protocol:hover,
.protocol-selected {
    background: #ffffff1a;
    color: #ffffff;
}

.protocol::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 4px;
    /* Width of the left line */
    background-color: #578ddb;
    /* Replace with the color of the line */
    border-radius: 3px 0 0 3px;
    /* Round corners for the line */
}

.new-metric {
    position: absolute;
    transform: translate(70%, -100%);
    width: 26px;
    height: 16px;
    background: #feab49;
    border-radius: 5px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1a224f;
    font-weight: 500;
    font-size: 8px;
}

.metric-skeleton-card {
    border: 1px solid #ffffff1a;
    border-radius: 15px;
}

.metric-protocol-mapping {
    position: absolute;
    width: 5px;
    height: 5px;
    rotate: 45deg;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    /* border-radius: 2px; */
}

.show-shadow {
    box-shadow: 0px 3px 6px 0px #0f104408;
}

.metric-available {
    background-color: #6fbfe180;
}

.metric-to-create {
    background-color: #257cff;
}

.metric-to-update {
    background-color: #6fbfe180;
}

/* Stack icons on top of each other */
.metric-protocol-mapping:nth-child(1) {
    top: -24px;
    left: -8.5px;
}

.metric-protocol-mapping:nth-child(2) {
    top: -24px;
    left: -8.5px;
}

.metric-protocol-mapping:nth-child(3) {
    top: -24px;
    left: -8.5px;
}

.sort-icon {
    color: 'rgba(255, 255, 255, 1)';
    fill-opacity: 0.75;
    font-size: '1rem';
}

.sort-icon:hover {
    fill-opacity: 1;
}

.horizontal-line {
    margin-top: 8px;
    width: 100%;
    height: 1px;
    background: rgba(111, 191, 225, 0.25);
}

.protocol-loading {
    display: flex;
    align-items: center;
    height: 24px;
    width: 100%;
    border-radius: 3px;
    background: #ffffff0d;
    padding: 10px;
    gap: 8px;
}

.protocol-loading::before {
    content: '';
    position: absolute;
    left: 10px;
    height: 24px;
    width: 2px;
    background-color: #ffffff1a;
    border-radius: 3px 0 0 3px;
}

.fav-warning-container {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 10px 8px 10px;
    border-radius: 2px;
    font-size: 10px;
    background: #ffff;
    color: #0f1044bf;
    position: relative;
}

.fav-warning-container::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 3px;
    background-color: rgba(235, 37, 65, 1);
}
.create_metric_btn {
    padding: 15px 0 5px 0;
}
