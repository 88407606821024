#ctrl {
    --heading-bg: rgba(15, 16, 68, 0.03);
    --heading-text-color: rgba(15, 16, 68, 0.75);
    --border-color: rgba(15, 16, 68, 0.1);
    --scroll-thumb-color: rgba(15, 16, 68, 0.031);
    --list_border: rgba(15, 16, 68, 0.1);
    --icon_bg: rgba(15, 16, 68, 0.5);
    --list-bg: #fff;
    --img_bg: #0f104408;
}

.p-0 {
    padding: 0 !important;
}

#edit-mode {
    --heading-bg: transparent;
    --heading-text-color: rgba(111, 191, 225, 0.75);
    --border-color: rgba(255, 255, 255, 0.1);
    --scroll-thumb-color: rgba(111, 191, 225, 0.1);
    --list_border: rgba(111, 191, 225, 0.1);
    --icon_bg: rgba(111, 191, 225, 0.5);
    --list-bg: rgba(111, 191, 225, 0.1);
    --img_bg: rgba(111, 191, 225, 0.2);
}
.ctrl_activity {
    height: inherit;
}

.main_activity .summaries_heading {
    background-color: var(--heading-bg) !important;
    font-size: 14px;
    font-weight: 500;
    padding: 13.44px 23.62px 13.44px 23.62px;
    height: 64px;
    line-height: 21px;
    text-align: left;
}
.main_activity .summaries_heading h1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: var(--heading-text-color);
}
.main_activity .activity .summaries_information .input_box {
    background-color: transparent;
}
.main_activity .panel_top_bar{
    height: 32px;
    align-items: center;
    padding-top: 0;
}
.main_activity .panel_top_bar svg path {
    fill: var(--icon_bg);
}
.main_activity .activity .summaries_information .input_box.showInput input,
.main_activity .activity .summaries_information .input_box input {
    padding: 5px 20px 5px 19px;
    font-family: Poppins;
    font-size: 12px;
    width: 100%;
    background-color: transparent;
    color: var(--list_color);
    text-align: left;
}
.main_activity .activity .summaries_information .input_box input {
    border-bottom: 1px solid transparent;
    display: none;
}
.main_activity .activity .input_box.showInput input {
    padding: 5px 20px 5px 19px !important;
}
.main_activity .activity .summaries_information .input_box svg path {
    fill: var(--icon_bg) !important;
}

.main_activity .activity .summaries_information .showInput input {
    border-color: var(--border-color);
    display: block;
}
.main_activity .summaries_information .input_box input::placeholder {
    color: var(--list_color);
    font-size: 12px;
}

.activity {
    padding: 0 23.62px 13.44px 23.62px;
    height: calc(100% - 57px);
}
.activity_list {
    margin-top: 10px;
    max-height: calc(100% - 50px);
}
.activity_list ul {
    overflow-y: auto;
    padding-right: 15px;
    height: 100%;
    margin-right: -15px;
}
.activity_list ul li {
    border-radius: 10px;
    padding: 7px;
    margin: 10px 0;
    border: 1px solid var(--list_border);
    background-color: var(--list-bg);
    cursor: default;
}

.activity_list ul li:hover .activity_content span,
.activity_list ul li:hover .activity_content p {
    color: var(--list_hover);
}

.activity_list ul li .activity_icon {
    width: 16px;
    height: 16px;
}

.activity_list ul li .activity_icon span {
    scale: 0.7;
}

.activity_list .activity-item .activity_icon {
    width: 16px;
    height: 16px;
}
.activity_list .activity-item .activity_icon span {
    scale: 0.7;
}

.activity_list ul li .activity_content p {
    font-size: 12px;
    margin-bottom: 0;
    line-height: 15px;
    font-weight: 500;
    color: var(--list_color);
}
.activity_list ul li .activity_content span {
    color: var(--list_color);
    font-size: 10px;
    font-weight: 400;
}

.activity_list ul::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
    background: var(--scroll-bar-color);
}

.activity_list ul::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 5px;
    background: var(--scroll-thumb-color);
}

.activity_list .empty_box h4 {
    color: var(--icon_bg);
}

.activity_list .empty_graph {
    background-color :var(--img_bg);
}

.activity_list .no_data {
    font-size: 13px;
    width: fit-content;
    color: var(--list_color);
    text-transform: capitalize;
    margin: 15px auto;
}

.inside_screen .activity .panel_top_bar{
    padding-top: 0;

}