#ctrl {
    --list_border: rgba(15, 16, 68, 0.1);
    --list_color: rgba(15, 16, 68, 0.5);
    --trigger_bg: rgba(15, 16, 68, 0.03);
    --icon_bg: rgba(15, 16, 68, 0.5);
    --scroll_bar_bg: rgba(15, 16, 68, 0.03);
    --track_bg: rgba(15, 16, 68, 0.03);
    --list_hover: rgba(15, 16, 68, 0.75);
    --active_border_color: #0014ff;
}

#edit-mode {
    --list_border: rgba(111, 191, 225, 0.1);
    --list_color: rgba(111, 191, 225, 0.5);
    --trigger_bg: rgba(111, 191, 225, 0.03);
    --icon_bg: rgba(111, 191, 225, 0.5);
    --scroll_bar_bg: rgba(111, 191, 225, 0.03);
    --track_bg: #1e2c58;
    --list_hover: rgba(111, 191, 225, 0.75);
    --active_border_color: rgba(111, 191, 225, 0.75);
}

.panel_top_bar .drawer_btn {
    min-width: 32px;
    max-width: 32px;
    height: 32px;
}
.panel_top_bar .drawer_btn.close_door svg {
    transform: rotate(180deg);
}
.panel_top_bar .hovered {
    border-radius: 4px;
    opacity: 0.7;
}
.panel_top_bar .hovered.active,
.panel_top_bar .hovered:hover {
    opacity: 1;
    background: rgba(15, 16, 68, 0.05);
}
.panel_top_bar .left_line {
    width: 1px;
    height: 25px;
    background-color: rgba(15, 16, 68, 0.1);
}

.panel_top_bar .sort {
    width: 44px;
    height: 32px;
}

.panel_top_bar .sort::selection {
    user-select: none;
}
.panel_top_bar .sort .sort_list {
    top: 35px;
    right: 0;
    z-index: 8;
    border-radius: 7px;
    background-color: #fff !important;
    box-shadow: 0px 6px 16px 0px rgba(15, 16, 68, 0.16);
    width: 155px;
}
.panel_top_bar .sort .sort_list ul li {
    font-size: 12px;
    color: #0f1044bf;
    padding: 10px 15px;
    width: 100%;
}

.panel_top_bar .sort .sort_list.active,
.panel_top_bar .sort .sort_list ul li.active,
.panel_top_bar .sort .sort_list ul li:hover {
    background: rgba(15, 16, 68, 0.03);
    opacity: 1;
}

.panel_top_bar .sort .sort_list .sort_head {
    padding: 10px;
    color: rgba(15, 16, 68, 0.75);
    font-size: 12px;
    font-weight: 500;
    margin: 0 15px 20px 15px;
    border-bottom: 1px solid rgba(15, 16, 68, 0.1);
}
.panel_top_bar .search-icon {
    min-width: 25px;
    height: 32px;
    cursor: pointer;
}
.panel_top_bar .search_box {
    overflow: hidden;
    width: 32px;
    position: relative;
    border-bottom: 1px solid transparent;
    height: 32px;
}

.panel_top_bar svg path {
    fill: var(--icon_bg);
    fill-opacity: 1;
}
.panel_top_bar .search_box.show_search {
    width: inherit;
    padding-right: 0;
    border-bottom: 1px solid;
    border-color: var(--list_border);
}

.panel_top_bar .search_box input {
    font-family: Poppins;
    font-size: 12px;
    width: 100%;
    font-weight: 400;
    line-height: 18px;
    background-color: transparent;
    color: var(--list_color);
    text-align: left;
}
.panel_top_bar .search_box input::placeholder {
    font-weight: 400 !important;
    font-size: 12px;
    color: var(--list_color);
}
.left_side_panel {
    padding: 15px;
    height: 95%;
}
.left_side_panel .protocol_list ul li .round {
    min-width: 15px;
    border-radius: 7px;
    min-height: 24px;
    margin-left: 2px;
}
.left_side_panel .protocol_list ul li .round .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(111, 90, 232, 1);
}

.left_side_panel .protocol_list ul li {
    height: 32px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    margin: 7px 0;
    border: 1px solid transparent;
    padding: 8px 8px 8px 0;
}

.left_side_panel .protocol_list ul li span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    max-width: 11.458vw;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--list_color);
    word-break: break-all;
}
.left_side_panel .protocol_list {
    overflow-y: auto;
    height: calc(100% - 50px);
}

.protocol_list::-webkit-scrollbar {
    display: none;
}
.protocol_list::-webkit-scrollbar {
    width: 5px;
    display: block;
    border-radius: 10px;
    background: var(--scroll_bar_bg);
}
.protocol_list::-webkit-scrollbar-thumb {
    background: var(--track_bg);
    border-radius: 10px;
}
.left_side_panel .protocol_list ul li .count {
    width: 34px;
    height: 24px;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    gap: 4px;
    color: var(--list_color);
    background: var(--trigger_bg);
    border-radius: 5px;
}
.left_side_panel .protocol_list ul li.active span,
.left_side_panel .protocol_list ul li:hover span {
    color: var(--list_hover);
}

.left_side_panel .protocol_list ul li,
.protocol_list ul li {
    width: 100%;
    border: 1px solid;
    border-color: var(--list_border);
}
.left_side_panel .protocol_list ul li {
    user-select: none;
}
.left_side_panel .protocol_list ul li.active_protocol {
    border-color: var(--active_border_color);
}

.left_side_panel .no_data {
    color: var(--list_color);
    text-align: center;
    font-size: 14px;
    margin-top: 50px;
}

.no_protocol {
    height: calc(100% - 2.917vw);
}
.no_protocol .img_box {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    background: var(--trigger_bg);
}
.no_protocol h6 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: var(--list_hover);
}
.no_protocol p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: var(--icon_bg);
}

.protocol_list .switch_btn {
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    min-width: 32px;
    max-width: 32px;
    position: relative;
    top: unset;
    right: unset;
    bottom: unset;
    margin-left: 5px;
    margin-right: 7px;
    padding: 0 3px;
    height: 16px;
    background: rgba(255, 255, 255, 0.1);
}
.protocol_list .switch_btn.active {
    background: rgba(111, 191, 225, 0.5);
    justify-content: end;
}

.protocol_list .switch_btn .switch_round {
    min-width: 12px;
    max-width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
}
