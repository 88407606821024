.calendar-container {
    margin-top: -20px;
    margin-left: 50px;
    padding: 20px;
    min-width: 80%;
    max-width: 80%;
    height: inherit !important;
}

.calendar-container .arrow {
    border: 1px solid #0F10441A;
    padding: 8px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 24px;
    height: 24px;
    color: #0F104480;
}

.calendar-container .arrow:hover {
    background-color: #0F10441A;
    cursor: pointer;
}

.calendar-container .today {
    color: #0F104480;
    font-size: 10px;
    border: 1px solid #0F10441A;
    border-radius: 50px;
    width: 63px;
    height: 32px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar-container .today:hover {
    color: #0F1044BF;
}

.calendar-container .today-selected {
    border: 1px solid #0F1044BF;
    background-color: #0F10440D;
    color: #0F1044BF;
}

.calendar-container .selected-date {
    background: #0F1044BF;
    color: #FFFFFF !important;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.calendar-container .date {
    margin-top: 5px;
    height: 24px;
    border-radius: 50px;
    color: #0F104480;
    font-size: 10px;
    font-weight: 400;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-items: center;
    white-space: nowrap;
}

.calendar-container .date-range-container {
    display: flex;
    border: 1px solid #0F10441A;
    border-radius: 50px;
    padding: 8px 10px;
    gap: 10px;
    font-size: 10px;
    color: #0F104480;
}

.calendar-container .date-range-container .date-range-item {
    cursor: pointer;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    padding: 4px 12px;
}

.calendar-container .date-range-container .date-range-item:hover {
    color: rgba(15, 16, 68, 0.75);
}

.calendar-container .date-range-container .date-range-item-selected {
    color: rgba(15, 16, 68, 0.75);
    background: #FFFFFF !important;
}

.date-container {
    display: flex;
    box-shadow: 0px 2px 4px 0px #0000001A;
    height: 40px;
    z-index: 9;
    background: #FFFFFF59;
    min-width: max-content;
    /* Ensures it does not shrink */
    flex-grow: 1;
    /* Allows it to expand dynamically */
    overflow: hidden;
}

.vertical-line-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit !important;
    position: relative;
    margin-top: 10px;
    margin-bottom: 40px;
}

.vertical-line {
    width: 2px;
    height: 100% !important;
    background-color: #0F10441A !important;
    margin-left: 50px;
}

.horizontal-line {
    position: absolute;
    width: 10px;
    /* Adjust width as needed */
    height: 1px;
    background-color: #0F10441A !important;
    left: 45px;
    /* Adjust position to align with the vertical line */
}

.horizontal-line:nth-child(1) {
    top: 0;
    margin-top: -1px;
}

/* Top */
.horizontal-line:nth-child(2) {
    top: 25%;
}

/* 25% from top */
.horizontal-line:nth-child(3) {
    top: 50%;
}

/* Middle */
.horizontal-line:nth-child(4) {
    top: 75%;
}

/* 75% from top */
.horizontal-line:nth-child(5) {
    bottom: 1.5%;
}

/* Bottom */

.target-text {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0%;
    color: #0F1044BF;
    margin-left: 10px;
}

.create-target-state-dot {
    width: 24px;
    height: 24px;
    padding: 8px;
    border: 3px solid #F1F1F5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0014FF;
    color: #FFFFFF;
}

.oversight-container {
    display: flex;
    height: inherit !important;
    overflow-y: hidden;
}

.dark-bg {
    background: rgba(0, 0, 0, 0.2);
    /* Darker */
}

.light-bg {
    background: rgba(0, 0, 0, 0.05);
    /* Lighter */
}

.date-body {
    display: flex;
    height: 95%;
    position: relative;
    overflow-x: auto !important;
    overflow-y: hidden;
    z-index: 1;
    scroll-behavior: smooth;
    width: 100%;
    max-width: 80vw;
    /* Restrict width to prevent overflow */
    white-space: nowrap;
    /* Keep elements in one line */
    background: repeating-linear-gradient(to right,
            #0F104403 0,
            #0F104403 7%,
            transparent 6%,
            transparent 15%);
    background-size: 100% 100%;
}

.blueprint-state-container {
    height: inherit !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 49.5%;
    margin-left: 10px;
}

.date-body .create {
    background: linear-gradient(343.85deg, #FFB366 8.4%, #FF5BD1 28.55%, #5A44FF 59.69%, #4D78FF 100%);
    width: 32px;
    height: 32px;
    border-radius: 50px;
    padding: 16px 16px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    color: #FFFFFFBF;
    position: relative;
    cursor: pointer;
    opacity: 95%;
    transition: all 0.3s ease-in-out;
}

.date-body .create:hover {
    width: 36px;
    height: 36px;
    color: #FFFFFF;
    opacity: 100%;
}

.date-body .create p {
    font-size: 20px;
    font-weight: 400;
}

.vertical-line-container .container {
    margin-left: 20px;
}

.hr-line {
    position: absolute;
    top: 49.5%;
    left: 0;
    margin-top: 14px;
    height: 1px;
    background-color: #0F10441A !important;
    transition: width 0.3s ease-in-out;
    width: auto;
    /* Will be set dynamically */
    min-width: 100%;
    /* Ensures at least full width initially */
    white-space: nowrap;
}

.blueprint-state-container .state {
    border-radius: 50%;
    cursor: pointer;
}

.blueprint-state-container .state.active {
    background: linear-gradient(343.85deg, #FFB366 8.4%, #FF5BD1 28.55%, #5A44FF 59.69%, #4D78FF 100%);
}

.blueprint-state-container .state.updated {
    background: rgba(210, 210, 220, 1);
    border: 3px solid rgba(255, 255, 255, 1);
}

.blueprint-state-container .state:hover {
    border: 1px solid #FFFFFF;
    transform: scale(1.5);
}

.blueprint-state-container .trigger-small {
    margin-top: 12.5px;
    width: 4px;
    height: 4px;
}

.blueprint-state-container .trigger-medium {
    margin-top: 12px;
    width: 6px;
    height: 6px;
}

.blueprint-state-container .trigger-large {
    margin-top: 10px;
    width: 10px;
    height: 10px;
}

.blueprint-state-container .trigger-today {
    width: 16px;
    height: 16px;
    margin-top: 8px;
}

.blueprint-state-container .trigger-snapshot {
    width: 22px;
    height: 22px;
    margin-top: 5px;
}

.blueprint-state-container .trigger-create {
    width: 24px;
    height: 24px;
    margin-top: 3px;
}

.state-container {
    position: relative;
    display: inline-block;
}

.state-content {
    width: 305px;
}

.report-card {
    width: 273;
    height: 32;
    display: flex;
    justify-content: space-between;
    border-width: 1px;
    border-radius: 10px;
    padding: 8px;
    background: #0014FF08;
    border: 1px solid #0F10441A;
}

.report-container {
    max-height: 140px;
    overflow-y: auto;
    padding-right: 5px;
}

.metric-change {
    width: 32px;
    height: 16px;
    gap: 8px;
    border-radius: 2px;
    padding: 4px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.positive {
    background: #AFEECF33;
    color: #37B677;
}

.negative {
    background: #EB25410D;
    color: #EB2541;
}

.vertical-line-container .category {
    font-size: 12px;
    font-weight: 400;
    color: rgba(15, 16, 68, 0.75);
    transform: rotate(-90deg);
}

.label-0 {
    position: absolute;
    top: 12%;
    left: -5px;
}

.label-1 {
    position: absolute;
    top: 40%;
    left: -5px;
}

.label-2 {
    position: absolute;
    top: 65%;
    left: -5px;
}

.label-3 {
    position: absolute;
    top: 85%;
    left: -5px;
}

.multiple-states {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
}

.multiple-states .state-item {
    position: absolute;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0;
    transform: translateY(0);
}

.multiple-states .state-item:first-child {
    opacity: 1;
    position: relative;
    z-index: 2;
}

.multiple-states:hover .state-item {
    opacity: 0.5;
}

.multiple-states:hover .state-item:first-child {
    opacity: 1;
}

/* Positioning dots above */
.multiple-states .state-item {
    transform: translateY(calc(var(--index) * -35px));
}

.date-container .day {
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.date-container .week {
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.date-container .month {
    min-width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.date-container .quarter {
    min-width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.date-container .year {
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}