.publish-planet-box .overview_top {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    top: 10px;
    height: 48px;
    padding: 0 16px;
    z-index: 9;
    width: max-content;
    justify-content: center;
    border-radius: 7px;
    box-shadow: 0px 4px 10px 0px #0f10441f;
    background: #0f104408;
}
.publish-planet-box .main_eva_kpi {
    max-width: max-content;
    width: 55vw;
    margin: auto;
    height: 70px;
    padding: 0 10px;
    overflow-y: hidden;
    overflow-x: auto;
}

.publish-planet-box .analytics_box {
    width: max-content;
}

.publish-planet-box .overview_top .no_content_box {
    color: rgba(15, 16, 68, 0.5);
    width: fit-content !important;
    margin: auto;
}

.publish-planet-box .overview_top .overview_info {
    height: 40px;
    width: max-content;
    display: flex;
    align-items: center;
    position: relative;
    gap: 5px;
    border-radius: 5px;
    cursor: pointer;
    padding: 8px 7px;
    border: 1px solid transparent;
    background-color: #fff;
}

.publish-planet-box .overview_top .overview_info:hover {
    box-shadow: 0px 3px 6px 0px rgba(15, 16, 68, 0.06);
}
.publish-planet-box .overview_top .overview_info .img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    overflow: hidden;
    outline: 1px solid #fff;
}
.publish-planet-box .overview_top .overview_info .img img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.publish-planet-box .overview_top .overview_info .organisation-img {
    max-width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 0;
}

.overview_info p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    text-align: left;
    margin: 0;
    color: rgba(15, 16, 68, 0.75);
}
.overview_info .tooltip_box {
    background: rgba(0, 20, 255, 0.75);

    width: max-content;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    height: fit-content;
    padding: 7px;
    color: #ffffff;
    line-height: 15px;
    text-align: left;
    left: 50%;
    top: -90%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
.overview_info .tooltip_box span {
    max-width: 403px;
    display: block;
}
.overview_info:hover .tooltip_box {
    display: flex;
}
.disable.publish-planet-box .overview_top:hover .tooltip_box {
    display: none;
}

.main_drop_down .MuiInputBase-input {
    padding-top: 0;
    padding-bottom: 0;
}
.main_drop_down .MuiInputBase-input > div {
    width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.vector_line {
    position: absolute;
    top: 31px;
    left: 50%;
    transition: height 3s ease-in-out;
}
.active_box {
    border: 1px solid rgba(0, 20, 255, 1) !important;
    box-shadow: 0px 3px 6px 0px rgba(15, 16, 68, 0.06) !important;
}

.circle_box .center_orbit {
    top: 0;
    left: 50%;
    width: 10px;
    height: 10px;
    z-index: 9;
    transform: translateX(-50%);
}

.lines-container {
    position: absolute;
    top: -125px;
    left: -48px;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.chat_box {
    width: 31.615vw;
    border-radius: 15px;
    position: absolute;
    bottom: 20px;
    z-index: 999;
}
.chat_box::before {
    position: absolute;
    content: '';
    background: radial-gradient(42.28% 42.28% at 50% 100%, rgba(0, 20, 255, 0.1) 0%, rgba(0, 20, 255, 0) 100%);
    bottom: -20px;
    left: 50%;
    width: 100%;
    height: 100px;
    transform: translateX(-50%);
}

.chat_input {
    width: 100%;
    margin: auto;
    padding: 10px;
    height: 40px;
    border-bottom: 1px solid rgba(15, 16, 68, 0.25);
}

.chat_input input {
    width: 100%;
    font-family: 'Poppins';
    font-size: 12px;
    background-color: transparent;
    font-weight: 400;
    line-height: 18px;
    z-index: 9;
    text-align: left;
    color: rgba(15, 16, 68, 0.75);
    height: 100%;
}
.chat_input input::placeholder {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(15, 16, 68, 0.50);
    text-align: left;
}


.chat_input button:disabled svg {
    cursor: not-allowed;
    fill: rgba(0, 20, 255, 0.15);
}

.chat_input button svg {
    cursor: pointer;
    fill: rgba(0, 20, 255, 0.75);
}
.kpi-tooltip {
    background: rgba(0, 20, 255, 0.75);
    max-width: 371px;
}

@media(max-width:1300px){
    .analytics_container{
        flex-direction: column;
    }
    .analytics-page-container .analytics_container .risk,
    .analytics-page-container .analytics_container .trends{
        width: 100%;
        margin: 15px 0;
    }
}